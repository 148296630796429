<template>
	<div class="pr-5">
		<p class="mx-0 mb-2.5 mt-5 text-xl font-bold">Search</p>
		<FilterSearchBar
			:search-term="ProductsStore.searchTermUserSelection"
			class="overflow-hidden rounded border border-text"
			@search="(term) => (ProductsStore.searchTermUserSelection = term)"
		/>

		<p class="mx-0 mb-2.5 mt-5 text-xl font-bold">Sort</p>
		<FilterSelect
			:options="ProductsStore.sortingOptions"
			:selection="ProductsStore.sortByUserSelection"
			aria="Product sorting"
			class="overflow-hidden rounded border border-text"
			@change-option="(option) => (ProductsStore.sortByUserSelection = option)"
		/>

		<p class="mx-0 mb-2.5 mt-5 text-xl font-bold">Product Type</p>
		<FilterCheckBox
			v-if="categoryOptionsCountHasValues"
			:options="ProductsStore.categoryOptions"
			:options-count="ProductsStore.categoryOptionsCount"
			:selection="ProductsStore.categoryUserSelection"
			name="category"
			aria="Category"
			@change-option="(option) => (ProductsStore.categoryUserSelection = option)"
		/>

		<p class="mx-0 mb-2.5 mt-5 text-xl font-bold">Lean</p>
		<FilterCheckBoxMulti
			v-if="leanOptionsCountHasValues"
			:options="ProductsStore.leanOptions"
			:options-count="ProductsStore.leanOptionsCount"
			:selection="ProductsStore.leanUserSelection"
			name="lean"
			aria="Lean"
			@change-option="(option) => (ProductsStore.leanUserSelection = option)"
		/>

		<p class="mx-0 mb-2.5 mt-5 text-xl font-bold">Price Tier</p>
		<FilterCheckBox
			v-if="priceTierOptionsCountHasValues"
			:options="ProductsStore.priceTierOptions"
			:options-count="ProductsStore.priceTierOptionsCount"
			:selection="ProductsStore.priceTierUserSelection"
			name="price-tier"
			aria="Price tier"
			@change-option="(option) => (ProductsStore.priceTierUserSelection = option)"
		/>

		<p class="mx-0 mb-2.5 mt-5 text-xl font-bold">Chapter</p>
		<FilterCheckBox
			v-if="chapterOptionsCountHasValues"
			:options="ProductsStore.chapterOptions"
			:options-count="ProductsStore.chapterOptionsCount"
			:selection="ProductsStore.chapterUserSelection"
			name="chapter"
			aria="Chapter"
			@change-option="(option) => (ProductsStore.chapterUserSelection = option)"
		/>

		<p class="mx-0 mb-2.5 mt-5 text-xl font-bold">Dominant Terpene</p>
		<FilterCheckBox
			v-if="dominateTerpeneOptionsCountHasValues"
			:options="ProductsStore.dominateTerpeneOptions"
			:options-count="ProductsStore.dominateTerpeneOptionsCount"
			:selection="ProductsStore.dominateTerpeneUserSelection"
			name="terpene"
			aria="terpene"
			@change-option="
				(option) => (ProductsStore.dominateTerpeneUserSelection = option as Terpene | 'All')
			"
		/>

		<p class="mx-0 mb-2.5 mt-5 text-xl font-bold">Brand</p>
		<FilterCheckBox
			v-if="brandOptionsCountHasValues"
			:options="ProductsStore.brandOptions"
			:options-count="ProductsStore.brandOptionsCount"
			:selection="ProductsStore.brandUserSelection"
			name="brand"
			aria="Brand"
			@change-option="(option) => (ProductsStore.brandUserSelection = option)"
		/>

		<p class="mx-0 mb-2.5 mt-5 text-xl font-bold">Price</p>
		<FilterRangeDouble
			v-if="hasValidPriceRange"
			:min="ProductsStore.priceRangeOptions.lowestPrice"
			:max="ProductsStore.priceRangeOptions.highestPrice"
			:min-selection="ProductsStore.minPriceUserSelection"
			:max-selection="ProductsStore.maxPriceUserSelection"
			aria="Price range slider"
			unit="$"
			:unit-suffix="false"
			@change-min-option="(option) => (ProductsStore.minPriceUserSelection = option)"
			@change-max-option="(option) => (ProductsStore.maxPriceUserSelection = option)"
		/>

		<p class="mx-0 mb-2.5 mt-5 text-xl font-bold">THC</p>
		<FilterRangeDouble
			v-if="hasValidThcRange"
			:min="ProductsStore.thcOptions.lowestThc"
			:max="ProductsStore.thcOptions.highestThc"
			:min-selection="ProductsStore.minThcUserSelection"
			:max-selection="ProductsStore.maxThcUserSelection"
			aria="THC double range slider"
			unit="%"
			:unit-suffix="true"
			@change-min-option="(option) => (ProductsStore.minThcUserSelection = option)"
			@change-max-option="(option) => (ProductsStore.maxThcUserSelection = option)"
		/>
	</div>
</template>

<script setup lang="ts">
import { useProductsStore } from '../../stores/productsStore';
import FilterCheckBox from '../ui/FilterCheckBox.vue';
import FilterCheckBoxMulti from '../ui/FilterCheckBoxMulti.vue';
import FilterSearchBar from '../ui/FilterSearchBar.vue';
import FilterSelect from '../ui/FilterSelect.vue';
import FilterRangeDouble from '../ui/FilterRangeDouble.vue';
import type { Terpene } from '~/types/app.types';

const ProductsStore = useProductsStore();

const categoryOptionsCountHasValues = computed(
	() =>
		ProductsStore.categoryOptionsCount &&
		!Object.values(ProductsStore.categoryOptionsCount).every((count) => count === 0),
);

const leanOptionsCountHasValues = computed(
	() =>
		ProductsStore.leanOptionsCount &&
		!Object.values(ProductsStore.leanOptionsCount).every((count) => count === 0),
);

const priceTierOptionsCountHasValues = computed(
	() =>
		ProductsStore.priceTierOptionsCount &&
		!Object.values(ProductsStore.priceTierOptionsCount).every((count) => count === 0),
);

const chapterOptionsCountHasValues = computed(
	() =>
		ProductsStore.chapterOptionsCount &&
		!Object.values(ProductsStore.chapterOptionsCount).every((count) => count === 0),
);

const dominateTerpeneOptionsCountHasValues = computed(
	() =>
		ProductsStore.dominateTerpeneOptionsCount &&
		!Object.values(ProductsStore.dominateTerpeneOptionsCount).every((count) => count === 0),
);

const brandOptionsCountHasValues = computed(
	() =>
		ProductsStore.brandOptionsCount &&
		!Object.values(ProductsStore.brandOptionsCount).every((count) => count === 0),
);

const hasValidPriceRange = computed(
	() =>
		ProductsStore.minPriceUserSelection !== null &&
		ProductsStore.maxPriceUserSelection !== null,
);

const hasValidThcRange = computed(
	() => ProductsStore.minThcUserSelection !== null && ProductsStore.maxThcUserSelection !== null,
);
</script>
